.App {
  text-align: center;
}

.App-header {
  background-color: #282c34; 
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  width: 100%; /* Take the full width of the parent */
  height: 100%; /* Stretch to fill the container vertically */
}

.header div {
  margin-right: 20px;
  max-width: 40%;
}
.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as needed */
}

.social-links img {
  height: 40px; /* Adjust size as needed */
  margin: 0 10px; /* Adjust spacing as needed */
}

.App-image {
  height: 200px; 
  border-radius: 50%; 
  margin-bottom: 20px;
  margin-left: 20px;
}


.scroll-to-continue {
  position: absolute; 
  bottom: 0; 
  text-align: center; 
}
.project-carousel {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  display:flex;
  align-items: center;
  justify-content:center ;
}
.project-whole{
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(0, 0, 0, 2);
min-height: 100vh;
}
.project {
  margin: 0 15px;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.project-description{
  width:80%
  }
  .project-body{
  display:flex;
  width:50%
  }
.project img,.modal-content img {
  max-width: 80%;
  height: auto;
}
.project-image {
  opacity: 0;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
.project:hover {
  transform: scale(1.05);
}
.project-image-visible {
  opacity: 1;
  transition: opacity 0.5s ease;
}
button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  cursor: pointer;
  /* Add more styling */
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-height: 80vh; /* Adjust the height as needed */
  overflow-y: auto; 
}

.close-button {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  cursor: pointer;
  z-index: 1001; /* To ensure it's above modal content */
}

.modal-wrapper {
  position: relative;
  width: 50%;
}



.view-more {
  display: inline-block;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-more:hover {
  background-color: #0056b3;
}

.modal-image {
  transition: opacity 0.5s ease-in-out;
  opacity: 0; /* Start with the image being transparent */
}

.modal-image-visible {
  opacity: 1; /* Fully visible when this class is added */
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.scroll-indicator {
  position: absolute;
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 1s infinite;
  font-size: 36px; 
}
.scroll-to-continue {
  text-align: center;
  margin-top: 20px;
}

.scroll-button {
  display: inline-block;
  margin-top: 10px;
  border: 2px solid #fff; /* Adjust color as needed */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  animation: bounce 1s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-button:hover {
  background-color: #fff;
  color: #000;
}

.contact{
  min-height: 100vh;
}
.contact-body{
  padding:20px
}
.contact-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Makes sure padding doesn't affect overall width */
}

.contact-form textarea {
  height: 100px;
  resize: vertical; /* Allows vertical resize only */
}

.contact-form button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button[type="submit"]:hover {
  background-color: #0056b3;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}
.thank-you-message {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  background-color: #dff0d8;
  color: #3c763d;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  height:3rem;
}

.thank-you-gif {
  max-height: 110%; /* Ensures the GIF's height does not exceed the message box */
  margin-right: 10px; /* Adds some space between the GIF and the text */
}
.about-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px 0; 
}

.about-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px; 
}
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40%;

}
.about h2 {
  margin-bottom: 10px; 
}

.about h4 {
  margin-top: 0;
  margin-bottom: 8px; 
}

.about p {
  margin-top: 0; 
}
.about-more{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-more p{
max-width: 50%;
}
.about-image {
  width: 150px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Space between image and text */
  border-radius: 20%; /* Circular image */
}
.image-click-prompt {
  text-align: center;
  margin-top: 10px;
  font-style: italic;
  cursor: pointer;
}

.view-projects-button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  animation: bounce 2s infinite;
}
.tech-stack {
  display:flex ;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tech-logos img {
  height: 40px; /* Adjust size as needed */
  margin: 0 10px; /* Adjust spacing as needed */
}
/* Targeting the scrollbar of the modal */
.modal-content::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the scrollbar track */
}

.modal-content::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}






